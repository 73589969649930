<template>
  <div class="billing-page">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div v-for="(groupItem, key) in carrierGroup"  :key="`group-${key}`" class="col-md-6">
                <div class="row">
                  <div class="col-md-12 mb-3 mb-3">
                    <h4 class="h4 text-center">{{ groupItem.label }}</h4>
                  </div>
                  <div class="col-md-12">
                    <table class="table">
                      <thead class="table-light">
                        <tr>
                          <th>Carrier</th>
                          <th>Type</th>
                          <th>Inbound</th>
                          <th>Outbound</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in groupItem.data" :key="`item-${item.id}`">
                          <td>{{ item.carrier }} <span v-if="item.registered !== null" :class="{ 'text-success': item.registered === 1, 'text-danger': item.registered === 0 }"><b> - {{ item.registered_str }}</b></span></td>
                          <td>{{ item.message_type.toUpperCase() }}</td>
                          <td>$ {{ item.inbound  }}</td>
                          <td>$ {{ item.outbound }}</td>
                        </tr>
                        <tr>
                          <td>Other</td>
                          <td>SMS</td>
                          <td>$ 0</td>
                          <td>$ {{ outboundFee }}</td>
                        </tr>
                        <tr>
                          <td>Other</td>
                          <td>MMS</td>
                          <td>$ 0</td>
                          <td>$ {{ outboundFee }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import Vue from 'vue'

export default {
  data() {
    return {
      loading: false,
      carrierGroup: [],
    }
  },

  computed: {
    settings() {
      return this.$store.getters['auth/settings']
    },
    outboundFee() {
      return this.settings && this.settings.outbound_carrier_fee
    }
  },

  mounted() {
    this.user = Vue.util.extend({ }, this.$store.getters['auth/user'])
    this.loadData()
  },

  methods: {
    loadData() {
      this.loading = true
      this.$store.dispatch('app/getCarrierFees', location.hostname)
        .then((data) => {
          this.carrierGroup = data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
  
  